import deepmerge from 'deepmerge';
import { config as baseConfig } from './base-devtest';

//
// dev.jiveworld.app
//
// continuous deployment from develop
//

export const config = deepmerge(baseConfig, {
  // showUnhandledErrors: true,

  cookieDomain: 'dev.jiveworld.app',

  website: {
    // urls: {
    //   marketingSite: 'https://www.test.jiveworld.app',
    // },
    // not relevant - no marketing site peer to dev/test
    cookieDomain: 'dev.jiveworld.app',
  },
});
